<template>
  <v-container>
    <v-row class="mb-3">
      <v-col cols="12" md="6">
        <v-card height="100%">
          <v-card-title>Ventas por Categoria</v-card-title>
          <v-card-text>
            <GChart type="PieChart" :data="chartCategoryGroup" :options="chartOptions" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card height="100%">
          <v-card-title>Ventas por producto</v-card-title>
          <v-card-text>
            <GChart type="PieChart" :data="chartproductGroup" :options="chartOptions" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-data-table
      calculate-widths
      :headers="header_orders[$vuetify.breakpoint.xsOnly]"
      :items="orders"
      :items-per-page="15"
      item-key="index"
      sort-by="issue_date"
      sort-desc
      mobile-breakpoint="0"
      class="table-cursor elevation-0"
      loading-text="Cargando ..."
      :search="search"
      @click:row="view_doc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> Orden de Compra Cliente</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="pa-2">
          <v-col>
            <v-text-field
              v-model="issueDateFrom"
              label="Desde"
              hide-details="auto"
              required
              outlined
              type="date"
              @change="get_oders()"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="issueDateTo"
              label="Hasta"
              hide-details="auto"
              required
              outlined
              type="date"
              @change="get_oders()"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="search"
              hide-details
              :items="status_lst"
              label="Estado"
              placeholder="Estado"
              outlined
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.delivery_date`]="{ item }" class="text-right">
        {{ item.delivery_date }}<br />
        <small>{{ item.delivery_time }}</small>
      </template>
      <template v-slot:[`item.total`]="{ item }" class="text-right">
        {{ "$ " + parseFloat(item.total).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="view_doc(item)"> mdi-magnify </v-icon>
      </template>
    </v-data-table>

    <v-data-table
      :headers="headers[$vuetify.breakpoint.xsOnly]"
      :items="orderItems"
      :items-per-page="5"
      class="elevation-1 mt-6"
      dense
      mobile-breakpoint="0"
      item-key="doc_code,ean"
      :loading="loading_status"
      loader-height="2"
      loading-text="Cargando ..."
    >
      <template v-slot:top> </template>
      <template v-slot:[`item.picture`]="{ item }">
        <v-list-item-avatar size="60">
          <v-img
            contain
            :src="getImagePhoto(item.account, item.store, item.picture)"
            v-if="item.picture"
          ></v-img>
        </v-list-item-avatar>
      </template>

      <template v-slot:[`item.product_name`]="{ item }">
        <span @click="editItem(item)">
          {{ item.product_name }}
          <br />
          <small
            v-if="item.description && !$vuetify.breakpoint.xsOnly"
            v-html="item.description"
          />
        </span>
      </template>

      <template v-slot:[`item.qty`]="{ item }">
        <span>
          {{ item.qty }}
        </span>
      </template>
      <template v-slot:[`item.pack`]="{ item }">
        <span>
          {{ item.pack + " " + item.uom }}
        </span>
      </template>

      <template v-slot:[`item.tItem`]="{ item }" @click="editItem(item)">
        <span>
          {{ "$ " + item.tItem.toLocaleString(2) }}
          <br />
          <small class="red--text text-decoration-line-through" v-if="item.discount > 0">
            {{ "$ " + item.discount.toLocaleString(2) }}
          </small>
        </span>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ "$ " + item.total.toLocaleString(2) }}
      </template>

      <template slot="body.append">
        <tr>
          <th>Totales</th>
          <th v-if="!$vuetify.breakpoint.xsOnly"></th>
          <th v-if="!$vuetify.breakpoint.xsOnly" class="text-center">
            {{ sumField(orderItems, "qty").toLocaleString(2) }}
          </th>
          <th v-if="!$vuetify.breakpoint.xsOnly"></th>
          <th v-if="!$vuetify.breakpoint.xsOnly"></th>
          <th v-if="!$vuetify.breakpoint.xsOnly"></th>
          <!-- <th v-if="!$vuetify.breakpoint.xsOnly"></th>
          <th v-if="!$vuetify.breakpoint.xsOnly"></th> -->
          <th class="text-right">
            {{ sumField(orderItems, "total").toLocaleString(2) }}
          </th>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { webserver, getToday, getIssueDate } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import docs_lst from "../../utils/docs.js";

import { GChart } from "vue-google-charts";

export default {
  components: { GChart },
  mounted() {
    this.company = this.$store.getters.company;
    //var params = this.$route.params;
    //console.log(params);
    this.get_oders();
  },
  data: () => ({
    categories: [],
    doc_type: null,
    docType: docs_lst().docType,
    items: [],
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    orderItems: [],
    search: "",
    status_lst: ["", "PENDIENTE", "DESPACHADO", "ENTREGADO"],
    header_orders: {
      false: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "Orden",
          align: "start",
          value: "doc_code",
          dataType: "text",
        },
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Entregar",
          align: "start",
          value: "delivery_date",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
        {
          text: "Estado",
          align: "end",
          sortable: true,
          value: "status",
        },
      ],
      true: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
    },
    headers: {
      false: [
        {
          text: "",
          align: "center",
          value: "picture",
          width: 50,
          groupable: false,
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
          groupable: false,
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "qty",
          dataType: "text",
          groupable: false,
        },
        {
          text: "Tamaño",
          align: "center",
          sortable: true,
          value: "size",
          dataType: "text",
          groupable: false,
        },
        {
          text: "Pack",
          align: "center",
          sortable: true,
          value: "pack",
          dataType: "text",
          groupable: false,
        },
        {
          text: "Cantidad",
          align: "start",
          sortable: true,
          value: "qtyt",
          dataType: "number",
          groupable: false,
        },
        // {
        //   text: "Costo",
        //   align: "end",
        //   sortable: true,
        //   value: "cost",
        //   dataType: "number",
        //   groupable: false,
        // },
        // {
        //   text: "Precio",
        //   align: "end",
        //   sortable: true,
        //   value: "price",
        //   dataType: "number",
        //   groupable: false,
        // },
        {
          text: "Total",
          align: "end",
          sortable: true,
          value: "tItem",
          dataType: "number",
          groupable: false,
        },
      ],
      true: [
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
          dataType: "number",
        },
      ],
    },
    issueDateFrom: getIssueDate(60),
    issueDateTo: getToday(),
    allItems: [],
    productGroup: [],
    chartproductGroup: null,
    CategoryGroup: [],
    chartCategoryGroup: null,
    chartOptions: {
      curveType: "function",
      legend: { position: "bottom" },
      interpolateNulls: true,
      crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
      isStacked: false,
      hAxis: {
        title: "Tipo",
        slantedText: true,
        slantedTextAngle: 90,
      },
    },
  }),
  methods: {
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    sumFieldPrice(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    getImagePhoto: function (account, code, src) {
      var url = "https://" + this.$store.getters.getHost + "/" + account + "/" + src;
      return url;
    },
    get_oders() {
      var qry = {
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
          { field: "date(issue_date)", operator: ">=", value: this.issueDateFrom },
          { field: "date(issue_date)", operator: "<=", value: this.issueDateTo },
          { field: "doc_type", operator: "=", value: "OR" },
        ],
      };
      webserver("get_doc_items", qry, (data) => {
        this.allItems = data;
        var result = [];
        data.reduce(function (res, value) {
          if (!res[value.doc_code]) {
            res[value.doc_code] = true;
            result.push({
              issue_date: value.issue_date,
              doc_code: value.doc_code,
              party_name: value.party_name,
              delivery_date: value.delivery_date,
              total: value.total,
              status: value.status,
            });
          }
          return res;
        }, {});

        this.orders = result;
        result = [];
        data.reduce(function (res, value) {
          if (!res[value.code]) {
            res[value.code] = { label: value.product_name, value: 0 };
            result.push(res[value.code]);
          }
          res[value.code].value += value.price_value * value.qty;
          return res;
        }, {});

        var dtSet = [];
        dtSet.push(["Producto", "Ventas"]);
        result.forEach((rg) => {
          var item = [];
          item.push(rg.label);
          item.push(rg.value);
          dtSet.push(item);
        });
        this.productGroup = result;
        this.chartproductGroup = dtSet;

        result = [];
        data.reduce(function (res, value) {
          if (!res[value.category]) {
            res[value.category] = { label: value.category, value: 0 };
            result.push(res[value.category]);
          }
          res[value.category].value += value.price_value * value.qty;
          return res;
        }, {});

        dtSet = [];
        dtSet.push(["Categoria", "Ventas"]);
        result.forEach((rg) => {
          var item = [];
          item.push(rg.label);
          item.push(rg.value);
          dtSet.push(item);
        });
        this.categoryGroup = result;
        this.chartCategoryGroup = dtSet;

        result = [];
        console.log(data);
        data.reduce(function (res, value) {
          if (!res[value.code]) {
            res[value.code] = {
              code: value.code,
              product_name: value.product_name,
              size: 0,
              pack: 0,
              uom: value.uom,
              qty: 0,
              qtyt: 0,
              cost: value.cost,
              price: value.price,
              total: 0,
              tItem: 0,
              discount: 0,
            };
            result.push(res[value.code]);
          }
          res[value.code].size += parseFloat(value.size);
          res[value.code].qty += parseFloat(value.qty);
          res[value.code].pack += parseFloat(value.pack);
          res[value.code].tItem += parseFloat(value.tItem);
          res[value.code].discount +=
            parseFloat(value.discount_value) *
            parseFloat(value.qty) *
            parseFloat(value.pack);

          res[value.code].qtyt =
            res[value.code].qtyt + parseFloat(value.pack) * parseFloat(value.qty);

          res[value.code].total += parseFloat(value.qty) * parseFloat(value.price);
          return res;
        }, {});
        this.orderItems = result;
        console.log(this.orderItems);
      });
    },
    view_doc(e) {
      // //console.log(e);
      // if (e.doc_type == 'OR') {
      this.$router.push({ name: "Doc_e", params: { did: e.doc_code } });
      // } else  {
      //   this.$router.push({ name: "Doc", params: { did: e.doc_code } });
      // }
    },
  },

  watch: {},
};
</script>

<style></style>
